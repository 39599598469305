.form {
    animation: sc .4s;
}

@keyframes sc {
    from {
        scale: 0;
    }
    to {
        scale: 1;
         }
}