@import './reset.css';
* {
    font-size: 14px;
    font-family: 'Montserrat', sans-serif;
    line-height: 1.25rem;
    font-weight: 500;
    box-sizing: border-box;
    --purple: #5652AB;
    --light-purple:#F2F3F5;
    --dark-grey:#424554;
    --dark:#1B1C1E;
    --white:#ffffff;
    --light-grey:#AFAFAF;
    --red:#EB2020;
    --green:#47AE17;
    color: var(--dark);
}
a {
    color: var(--light-grey);
    cursor: pointer;
}
button {
    border: none;
    background-color: inherit;
}
.section {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100dvh;
    height: 100vh;
    background-color: var(--white);
}
.form {
    width: 50%;
    height: 50%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    overflow: hidden;
    border-radius: 18px;
    border: 5px solid var(--dark);
    position: absolute;
    inset: 50% 50%;
    translate: -50% -50%;
    background-color: var(--dark);
}
form:has(.form_input[type='tel']){
    height: 70%;
}
.form_black-block {
    display: flex;
    height: 15%;
    width: 100%;
    justify-content: center;
    align-items: center;
    background-color: var(--dark);
}
.form_wrap {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    gap: 1rem;
    flex-direction: column;
    background-color: var(--light-purple);
}
.form_title {
    text-align: center;
    color: var(--white);
}
.form_elem {
    color: var(--light-grey);
}
.input_wrap {
    display: flex;
    flex-direction: column;
    width: 60%;
    color: var(--light-grey);
}
.form_input {
    border-radius: 5px;
    border: 2px solid var(--light-grey);
    padding: 0.4rem;
}
.btn_auth {
    padding: 14px 30px;
    background-color: var(--purple);
    color: var(--white);
    border-radius: 9px;
}

.btn_wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    margin-left: 1rem;
}
.btn {
    background-color: inherit;
    color: var(--light-grey);
    border: none;
    display: flex;
    align-items: center;
    font-weight: 400;
}
.btn_text {
    margin-left: 0.8rem;
    color: var(--light-grey);
}
.container {
    width: 95%;
    height: 95%;
    border-radius: 1.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: var(--white);
    margin: 1rem 0.7rem;
}
.main_info {
    height: 15%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1rem;
}
.main-title {
    font-size: 18px;
    font-weight: 500;
    margin-left: 4rem;
}
.main {
    border-radius: 20px;
    background-color: inherit;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
    overflow: hidden;
}
/*table*/
.table_list {
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    gap: 1.5rem;
    align-items: stretch;
    height: 100%;
    width: 100%;
    overflow: auto;
    padding-bottom: 2rem;
    background-color: var(--light-purple);
    overflow-x: hidden;
}
.table_title {
    background-color: var(--white);
}

.table_item {
    position: relative;
    min-height: 2.5rem;
    display: grid;
    grid-template-columns: repeat( auto-fit, minmax(100px, 1fr));
    grid-template-rows: minmax(100%,1fr);
    align-items: center;
    padding: 0 1rem;
    gap: 1rem;
    justify-items: legacy;
    grid-auto-flow: column;
}
.table_item:before {
    content: '';
    position: absolute;
    right: 50%;
    bottom: -10%;
    width: 93%;
    border-bottom: 1px solid var(--light-grey);
    translate: 50% 0;
}
.table_item:first-child:before{
    display: none;
}
.table_item-block {
    padding-left: 1rem;
}
.table_item-block-title {
    text-align: center;
}

.table_item-block-inp {
    padding-left: 0;
    display: grid;
    align-content: center;
}
.brlf {
    text-align: left;
}
/*table*/
.userAddBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    background-color: var(--purple);
    border-radius: 6px;
    padding: 1rem;
}
.userAddBtn span {
    color: var(--white);
}

/*admin link style*/
.admin_link {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: left;
    cursor: pointer;
    gap: 1rem;
    padding-left: 1rem;
}
.admin_link svg {
    width: 20px;
}
.admin_link_text {
    color: var(--light-grey);
}
.admin_link.active {
    background-color: var(--dark-grey);
    border-radius: 9px;
    color: var(--dark-grey);
}
.admin_link.active .admin_link_text {
    color: var(--white);
}
/*admin link style*/
