.admin_logo {
    font-size: 22px;
    font-weight: 700;
    color: var(--white);
    text-align: left;
    margin-left: 1rem;
}
.section {
    background-color: var(--dark);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100dvh;
    height: 100vh;
}
.admin_nav {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    margin: 2rem 0 3rem 0.7rem;
    height: 85%;
    width: 20%;
}
.admin_list {
    border-bottom: 1px solid var(--light-grey);
    height: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    gap: 36px;
}
.admin_item {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 4rem;
    position: relative;
}


/*.admin_item.active .managed_counter {*/
/*    color: var(--white);*/
/*}*/
.managed_counter {
    border-radius: 50%;
    background-color: var(--purple);
    width: 1rem;
    height: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: 700;
}